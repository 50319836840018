














import { Component, Vue, Prop } from 'vue-property-decorator'
import reportService from '@/services/reportService'
import utility from '@/common/utility'

@Component
export default class UpDownBar extends Vue {
  name = 'UpDownBar'
  @Prop() sampleFile: string
  @Prop() downFile: string
  @Prop() downType: 'stock' | 'option'

  refresh () {
    this.$emit('refresh', true)
  }

  async uploadExcel (option: { file: File }) {
    if (option.file != null) {
      const res = await reportService.uploadExcel(option.file, 'option')
      if (res.Result) {
        this.$emit('refresh', false)
      } else {
        await this.$alert(res.Error)
      }
    }
  }

  async downloadSample () {
    try {
      const blob = await reportService.downloadSample(this.downType)
      utility.download(this.sampleFile, blob, 'application/octet-stream')
    } catch (e) {
      await this.$alert(e)
    }
  }

  async download () {
    try {
      const blob = await reportService.download(this.downType)
      utility.download(this.downFile, blob, 'text/csv')
    } catch (e) {
      await this.$alert(e)
    }
  }
}
